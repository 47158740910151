@import "./Normalize.scss";

$color-primary: #45BEBC;
$color-secondary: #f3ca76ff;

$color-black: #111;

$color-alert: #E76161;


$cn-Middle-width: 70%;
$cn-Left-width: 13%;
$cn-Right-width: 100% - $cn-Left-width - $cn-Middle-width;

$box-shadow-flush: 1px 1px 5px 0px rgba(#000, 0.3);

$grey-border: rgba(#000, 0.2);

$green-border: $color-primary;


body {
  background: radial-gradient(farthest-corner at 0% 0%, #FFF, #f3ca76ff);
  min-height: 100vh;
}

.rationale {
  max-width: 800px;
}

// CardNumber Input
.cn {

  .payment-provider-unknown &-CardType {
    background: url(anonymous_card.svg) no-repeat center center;
  }

  .payment-provider-visa &-CardType {
    background: url(visa-logo.png) no-repeat center center;
    background-size: 40px auto;
  }

  .payment-provider-mastercard &-CardType {
    background: url(mc_logo_scaled.png) no-repeat center center;
    background-size: 40px auto;
  }


  &-Container {
    padding-top: 2rem;
  }

  &-InputWrapper {
    padding: 0.5rem 0;
    margin-right: 2.5rem;
    position: relative;

    &--ccode {
      flex-basis: 100px;
    }


    &--cvc .cn-Input {
      text-align: center;
    }

    &--expiry .cn-Input {
      text-align: center;

      &:first-child {
        border-right: 1px solid $grey-border;
      }
    }


      &--pan &_Pretext {
        display: block;
        width: 100%;
        flex-basis: 100%;
        border-bottom: 1px solid $grey-border;

        @media screen and (min-width: 700px) {
          display: block;
          border: none;
          width: 50px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          pointer-events: none;
        }

      }

    &_Descriptor {
      padding: 0.5rem 0;
      font-weight: bold;
    }

    &_Pretext {
      padding: 1rem 0.5rem;
    }

    &_Fields {
      display: flex;
      flex-flow: row;
      background-color: #FFF;
      box-shadow: $box-shadow-flush;
      border-radius: 5px;
      width: 100%;
      position: relative;

      &--name {
        max-width: 500px;
      }

    }
  }

  &-Submit {
    border-radius: 5px;
    background-color: #999;
    padding: 1rem 3rem;
    color: #FFF;
    border: none;
    cursor: pointer;
    transition: all 0.3s;

    .card-details-valid & {
      box-shadow: $box-shadow-flush;
      background-color: #333;
    }
  }

  &-Row {
    display: flex;
    flex-flow: row;

    &--between {
      justify-content: space-between;
    }
  }

  &-Input {
    border: none;
    // box-shadow: $box-shadow-flush;
    box-sizing: border-box;
    padding: 1rem 0.5rem;
    background-color: transparent;
    width: 100%;


    &--cardnumber {
      border-radius: 0;
      box-shadow: none;
      border-right: 1px solid rgba(#000, 0.1);
      flex-basis: 25%;
      width: 25%;

      text-align: center;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }


    &--ccode {
      // width: 4rem;
      text-align: right;
      position: relative;
      max-width: 80px;

      &:before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        content: '+';
      }
    }
  }
}


.acc-Challenge {
    max-width: 980px;
    margin: 0 auto;
    padding: 10px;

    & &_Title {
        font-size: 1.5rem;
        font-family: "Abel", sans-serif;
    }
}

.App-Title {
    text-align: center;
    text-styling: capitalize;
    font-family: "Abel", sans-serif;
    color: $color-secondary;
}

.hed-Header {
  display: flex;
  flex-flow: row wrap;
  padding: 2rem 0;

  &_LeftContainer {
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }

  &_RightContainer {
    display: flex;
    align-items: center;
  }

  &_Title {
    font-size: 3.2rem;
    font-family: "Abel", sans-serif;
    margin: 0;
  }

  &_Logo {
    min-width: 120px;
  }

}

.App {
  max-width: 1000px;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
}


// .App-logo {
//     margin: 0 auto;
//     display: block;
//     margin-top: 40px;
//     height: 100px;
// }
//


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $color-black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.en-Disclaimer {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    font-size: 0.6rem;
    font-style: italic;
    margin-top: 1rem;
}

.msg {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto;

    display: flex;
    flex-flow: row;
    align-items: center;

    &--success {
        background-color: white;
        box-shadow: $box-shadow-flush;
    }

    &-Checkmark {
        border-radius: 50%;
        background-color: #f3ca76ff;
        width: 50px;
        height: 50px;
        margin-right: 1rem;
        font-size: 20px;
        box-sizing: border-box;
        position: relative;


        img {
            position: absolute;
            height: 20;
            width: 20;

            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

// Error Message Box
.msg-Message {
  box-sizing: border-box;
  padding: 1rem 0 1rem 2rem;
  position: relative;

  &_Icon {
    position: absolute;
    top: 1.7rem;
    left: 0;
  }

  color: $color-alert;

  &_Leader {
  padding: 0.7rem 0;
  font-weight: bold;
  }
}

